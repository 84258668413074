<script>
import APIAdmin from '@app/services/API/Admin';
import MetricsWrapper from '@app/components/MetricsWrapper.vue';
import AdminStat from '@app/components/AdminStat.vue';

export default {
  components: { MetricsWrapper, AdminStat },
  i18n: {
    messages: {
      fr: {
        revenus: 'Revenus',
        stores: 'Stores',
        trainings: 'Formations',
        customers: 'Apprenants',
      },
    },
  },
  data() {
    return {
      stats: {},
      form: { dates: [null, null] },
      statsLists: {
        revenus: [
          'user_generated_revenue', 'affiliation_revenue', 'customer_generated_revenue',
        ],
        stores: [
          'nb_stores', 'nb_users',
          'nb_pro', 'percentage_pro', 'avg_pro_delay_in_sec', 'nb_pro_to_percent',
          'nb_stores_with_at_least_a_training', 'percentage_stores_with_at_least_a_training',
          'nb_deleted_stores', 'avg_deletion_delay_in_sec',
          'nb_stripe_connect', 'avg_stripe_connect_delay_in_sec',
        ],
        trainings: [
          'nb_trainings', 'nb_trainings_in_draft', 'nb_training_items', 'nb_training_items_in_draft',
          'avg_first_training_delay_in_sec', 'avg_training_item_per_training',
        ],
        customers: ['nb_customers', 'avg_customer_per_store', 'nb_comments'],
      },
      isFetching: false,
    };
  },
  watch: {
    'form.dates': {
      handler: 'fetchStats',
    },
  },
  methods: {
    fetchStats() {
      let [begin, end] = this.form.dates;

      begin = this.$moment(begin).format('YYYY-MM-DD');
      end = this.$moment(end).format('YYYY-MM-DD');

      this.isFetching = true;
      APIAdmin.getStats({ begin, end })
        .then(({ data }) => (this.stats = data))
        .finally(() => (this.isFetching = false));
    },
  },
};
</script>

<template>
  <MetricsWrapper v-model="form.dates" :isLoading="isFetching">
    <template #title>
      Indicateurs
    </template>
    <template #default>
      <b-skeleton v-if="isFetching" height="600" />
      <template v-else>
        <template v-for="(list, listKey) in statsLists">
          <h2 :key="`${listKey}Title`" class="title is-4" v-t="listKey" />
          <div v-if="stats.current" :key="`${listKey}Stats`" class="columns is-multiline">
            <AdminStat
              v-for="statKey in list"
              :key="statKey"
              class="column is-4"
              :statKey="statKey"
              :statValue="stats.current[statKey]"
              :statPreviousValue="stats.previous[statKey]"
            />
          </div>
        </template>
      </template>
    </template>
  </MetricsWrapper>
</template>
